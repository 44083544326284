/* //////////////////////////////FIRST////////////////////////////// */

.main {
  /* overflowY: 'hidden' */
}

.main::-webkit-scrollbar {
  overflow: hidden;
}

.homeView {
  height: 100vh;
  min-height: 50vh;
  background-image: url('../images/home_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.7); 
  background-blend-mode: multiply;
  justify-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.homeViewFont {
  font-weight: bolds;
  font-family: 'Poppins';
  font-size: calc(40px + (85 - 40) * ((100vw - 300px) / (1600 - 300)));
  color:white;
  text-align: center;

  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .15em; 
  animation: animated-text 1.5s steps(29) 1s 1 normal both,
             animated-cursor 600ms steps(29) infinite;
}

@keyframes animated-text{
  from{width: 0;}
  to{width: 100%;}
}

@keyframes animated-cursor{
  from{border-right-color: rgb(255, 255, 255);}
  to{border-right-color: transparent;}
}

.helloWorld {
  flex-shrink: 1;
  align-self: center;
}

.homeSecondFont {
  font-weight: bolds;
  font-family: 'poppins';
  font-size: calc(13px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  color:white;
  transition: 0.1s;
  text-align: center;
}

.homeFirstView a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font : normal 400 20px/1 'Poppins';
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.homeFirstView a:hover {
  opacity: .5;
}

#homeFirst a {
  padding-top: 60px;
}
#homeFirst a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
}
#homeFirst a span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* ///////////////SECOND//////////////// */

.homeSecondView {
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 20px;
}

.whoAmI {
  color: gray;
  font-size: calc(35px + (40 - 35) * ((100vw - 300px) / (1600 - 300)));
  margin-top: 20px;
  font-family: "Poppins";
}

.aboutFont {
  font-family:'Poppins';
  color: rgb(93, 93, 93);
  font-size: 20px;
  text-align: center;
}

.name{
  font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
}

.designation {
  font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: rgb(93, 93, 93);
  font-family: "Poppins";
}

.company {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: rgb(93, 93, 93);
  margin-top: -10px;
  font-family: "Poppins";
}

.parallax {
  padding: 40px;
  /* height: 50vh; */
  /* display: flex; */
  /* flex: 1; */
  width: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.userImage {
  background-color: aliceblue;
  margin-top: 20px;
  margin-bottom: 20px;
}

.userData{
  animation-name: slideInLeft;
  animation-duration: 3s;
}

.userAbout{
  animation-name: slideInRight;
  animation-duration: 3s;
}

/* ////////////third//////////////// */

.homeThirdView {
  padding: 20px;
  background-color: white;
  align-items: center;
  font-family: "Poppins";
} 

.projectCardBody {
  margin-top: 20px;
  border-width: 0px;
}

.projectCard {
  border-width: 1px;
  transition:  0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 10px;
}

.projectCard:hover{
  box-shadow: 3px 5px 23px -7px rgba(0,0,0,0.95);
}

.projectFont {
  font-size: calc(30px + (30 - 30) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Poppins';
  color: gray;
  font-weight: bold;
  margin-left: 5px;
  text-align: center;
  margin-top: 20px;
}

.projectImage {
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  /* height: 50%; */
}

/* ////////////fourth/////////////////// */

.homeFourthView{
  background-color: rgb(27, 27, 27);
  /* padding: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30vh;
  font-family: "Poppins";
}

.socialView {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
}

.socialIcon {
  border-width: 0px;
  transition: all 0.1s;
  object-fit: contain;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.contactFont{
  font-size: calc(20px + (25 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  margin-top: 20px;
}

.craftedFont {
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ///////////////skills view/////////////////// */

.skillsView {
  /* height: 100vh; */
  /* min-height: 50vh; */
  background-image: url('../images/skills_bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.7); 
  background-blend-mode: multiply;
  justify-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  font-family: "Poppins";
}

.skillTitle {
  font-size: calc(30px + (30 - 30) * ((100vw - 300px) / (1600 - 300)));
  font-family: 'Poppins';
  color: white;
  font-weight: bold;
  margin-left: 5px;
  text-align: center;
}

.skillsList {
 align-self: center;
 align-items: center;
 display: flex;
 justify-content: center;
}

.skillsBody {
  padding: 100px;
}

.iconView {
transition: .3s;
margin-bottom: 40px;
}

.skillName {
  color: #fff;
  font-family: "Poppins";
}

.skillsIcon {
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
}

a {
  color: transparent;
  text-decoration: none;
}

a:hover 
{
   color:transparent; 
   text-decoration:none; 
   cursor:pointer;  
}


/* loader */

.loader{
  /* display: flex;
  position: absolute;
  align-self: center; */

  position: absolute;
  top: 0;
  left : 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 2;
  transition: ease-out 5s;
  animation:  ease-out 2s linear infinite;
  font-family: "Poppins";
}